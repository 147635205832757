import { environment as defaultEnvironment } from './environment.defaults';
export const environment = {
  ...defaultEnvironment,
  production: true,
  apiPath: 'https://goldsoft-api.kpvsolutions.com/',
  apiDomain: 'goldsoft-api.kpvsolutions.com/',
  log: false,
  flags: {
    useNewHeader: false
 }
};